import React from 'react';
import env from '../../Config';
import { Link } from 'react-router-dom'
import './Styles.scss'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const Aboutus = () => {
  return (
    <section className='welcome-sec base-pad pt-0'>
      
      <div className='position-relative'>
        <img src={env.baseUrl + '/images/about-in-br.jpg'} className='img-fluid' />
        <div className="bredcremb">
          <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 text-center">
              <h4 className='page-name'>About Us</h4>
              <Link to='/'>Home</Link> 
              <span className='mx-2'>/</span>
                <span>About Us</span>
                <span className='mx-2'>/</span>
              <span>About Us</span> 
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className="container rowspace mt-5 ">
        <div className="row">
          <div className="col-lg-6 mt-5 pt-lg-5">
            <h6 class="sec-heading">ABOUT US</h6>
            <h2 className='base-h1'> Empowering Success: Learn About Unique Mind Solutions</h2>
            
            <p className=' pe-lg-4'>
              At Unique Mind Solutions, we're more than just a technology company. We're innovators, problem-solvers, and partners dedicated to empowering businesses through cutting-edge solutions. With a focus on creativity, collaboration, and customer satisfaction, we harness the power of technology to drive success and inspire growth. Our team of skilled professionals brings together diverse expertise and a passion for excellence, ensuring that every project we undertake exceeds expectations. From software development to digital transformation, we're committed to delivering results that make a difference. Discover the difference Unique Mind Solutions can make for your business today
              </p>
            {/* <Link className='read-more-btn'>Read more <i class="las la-arrow-right"></i></Link> */}
          </div>


          <div className="col-lg-6 ">
            <div className="row">
              <div className="col-lg-6  p-3">
                <img src={env.baseUrl + '/images/about-in-img1.avif'} className='img-fluid' />
              </div>

              <div className="col-lg-6 p-5 ">
                <img src={env.baseUrl + '/images/about-in-img2.jpg'} className='img-fluid' />
              </div>

            </div>

            <div className="row">
              <div className="col-lg-6  p-5">
                <img src={env.baseUrl + '/images/about-in-img3.avif'} className='img-fluid' />
              </div>

              <div className="col-lg-6  p-3">
                <img src={env.baseUrl + '/images/about-in-img4.jpg'} className='img-fluid' />
              </div>
            </div>

          </div>

        </div>
      </div>

      {/* <div class="container-fluid">
        <div className="container-cust">
          <div className="row">
            <img src={env.baseUrl + '/images/aboutusbanner2.jpg'} className='img-fluid' />
          </div>
        </div>
      </div> */}


      <div class="container-fluid mt-5">
        <div className="container-cust">
          <div className="row">

            <div className="col-lg-6">
              <img src={env.baseUrl + '/images/aboutusvideo.jpg'} className='img-fluid rounded' />
            </div>
            <div className="col-lg-6 ps-lg-5">
              <h6 className='sec-heading mt-5 pt-lg-5'>HOW DO WE WORK</h6>
              <p className='fs-4'> We work collaboratively, leveraging expertise and creativity, to deliver customized solutions that exceed expectations and drive tangible results</p>

            </div>
          </div>
        </div>
      </div>



      {/* <div className="mt-1">
        <img src={env.baseUrl + '/images/aboutus2.jpg'} className='img-fluid' />
      </div> */}

      {/* <div className="textalign mt-5 pt-lg-3">
        <h6 className='sec-heading mt-5'>WE MAKE </h6>
        <h2> Discover Trust Team <p>and Our Experts</p></h2>
      </div> */}
      <div className="container-fluid">
        {/* <div className="container-cust">
          <div className="row">
            
            <div className="col-lg-8 offset-lg-2 mb-5 mt-3">
                  <Splide
                    options={{
                      rewind: true,
                      gap: '20px',
                      autoplay: true,
                      type: 'loop',
                      drag: true,
                      interval: 5000,
                      perPage: 3,
                      breakpoints: {
                        640: {
                          perPage: 1,
                        },
                        992: {
                          perPage: 3,
                        },
                        1200: {
                          perPage: 3,
                        },
                      }
                    }}
                    aria-label="My Favorite Images"
                  >
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                      <img src={env.baseUrl + '/images/team-mem-pic-1.jpg'} className='img-fluid' />
                        <h5>Sunjana Marry</h5>
                        <p>CEO</p>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                        <img src={env.baseUrl + '/images/team-mem-pic.jpg'} className='img-fluid' />
                        <h5>Shekhar V</h5>
                        <p>Senior software engineer</p>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                        <img src={env.baseUrl + '/images/team-mem-pic.jpg'} className='img-fluid' />
                        <h5>Mumtaz M</h5>
                        <p>Senior software engineer</p>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                      <img src={env.baseUrl + '/images/team-mem-pic.jpg'} className='img-fluid' />
                      <h5>Upendar M</h5>
                        <p>Senior software developer</p>
                      </div>
                    </SplideSlide>
                    
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                      <img src={env.baseUrl + '/images/team-mem-pic.jpg'} className='img-fluid' />
                      <h5>Harsha S</h5>
                        <p>Junior Software Engineer</p>
                      </div>
                    </SplideSlide>
                    </Splide>
                </div>
          </div>
        </div> */}


        {/* <div className="textalign" >
        <h6 className='sec-heading mt-5'>CLIENT FEEDBACK</h6>
        <h2>What Our Clients Say</h2>
        <p>Working with Unique Mind Solutions has been an absolute pleasure. Their professionalism, expertise, and </p>
          <p> dedication to delivering exceptional results are unparalleled. We're thrilled with the outcome and look forward to continued collaboration!</p>
      </div> */}


      </div>


 

      





      </section>
  )
}

export default Aboutus