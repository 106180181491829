import React from 'react';
import env from '../../Config';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';


export default function index() {
  return (
    <div className=''>

      <div className='position-relative'>
        <img src={env.baseUrl + '/images/why-in-br.jpg'} className='img-fluid' />
        <div className="bredcremb">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h4 className='page-name'>Why Choose Us</h4>
                <Link to='/'>Home</Link>
                <span className='mx-2'>/</span>
                <span>About Us</span>
                <span className='mx-2'>/</span>
                <span>Why choose Us</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div >
        <img src={env.baseUrl + '/images/why-in-br.jpg'} className='img-fluid' />
      </div> */}
      <section className='welcome-sec base-pad'>
        <div class="container-fluid ">
          <div className="container-cust">
          
            <div className="row my-3">

              <div className="col-lg-8 offset-lg-2 text-center mb-5">
                <h2 class="base-h1">How We can Help of Your Business Grow And Successful</h2>
              </div>

              <div className="col-lg-4">
                <h6 className='sec-heading mt-5'>IT Design Project</h6>
                <p>
                  Crafting innovative IT solutions tailored to your needs, ensuring efficiency, scalability, and seamless integration
                </p>
                <div className="row my-3">
                  <div className="col-lg-12">
                    <h6 className='sec-heading mt-5'>Product Engineer</h6>
                    <p>
                      Product engineers innovate, design, and optimize products to meet customer needs, ensuring quality and efficiency
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h6 className='sec-heading mt-5'>Cloud Integration</h6>
                <p>
                  Seamlessly integrate cloud solutions for enhanced scalability, efficiency, and agility in your business operations
                </p>
                <div className="row my-3">
                  <div className="col-lg-12">
                    <h6 className='sec-heading mt-5'>IT Consultancy</h6>
                    <p>
                      Strategic IT consultancy providing expert guidance to optimize technology, enhance performance, and drive business success
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">

                <img src={env.baseUrl + '/images/how-help-img1.avif'} className='img-fluid rounded' />
                <div className="row my-3">
                  <div className="col-lg-6">
                    <img src={env.baseUrl + '/images/how-help-img2.avif'} className='img-fluid rounded' />
                  </div>
                  <div className="col-lg-6">
                    <img src={env.baseUrl + '/images/how-help-img3.avif'} className='img-fluid rounded' />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='my-5'>
          <img src={env.baseUrl + '/images/whychoosebanner2.jpg'} className='img-fluid' />
        </div>

        <div class="container">
          <div className="container-cust textalign">
            <h6 className='sec-heading mt-5'>OUR SERVICE</h6>
            <h2 className='base-h1'>
              <p>We Offer a Wide Variety</p>
              <p>of IT Services</p>
            </h2>
            <div className="row my-3">
              <div className="col-lg-4">
                <div className="industry-card">
                <i class="las la-code"></i>
                  <h5 className="indus-card-headng">Web Development</h5>
                  <p>
                    UMS specializes in crafting dynamic websites tailored to your needs, ensuring seamless functionality and engaging user experiences.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="industry-card">
                <i class="las la-lock-open"></i>
                  <h5 className="indus-card-headng">Cyber Security</h5>
                  <p>
                    UMS offers robust cybersecurity solutions, safeguarding your digital assets with cutting-edge technologies and proactive measures.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="industry-card">
                <i class="las la-briefcase"></i>
                  <h5 className="indus-card-headng">IT Consultancy</h5>
                  <p>
                    At Unique Mind Solutions, our IT consultancy offers tailored solutions to optimize technology, streamline operations, and drive growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-lg-4">
                <div className="industry-card">
                <i class="las la-qrcode"></i>
                  <h5 className="indus-card-headng">Digital Services</h5>
                  <p>
                    UMS provides comprehensive digital services, empowering businesses with innovative solutions for online success and growth.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="industry-card">
                <i class="las la-pencil-ruler"></i>
                  <h5 className="indus-card-headng">Product Design</h5>
                  <p>
                    UMS excels in product design, crafting innovative solutions that marry form and function for optimal user experiences.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="industry-card">
                <i class="las la-users"></i>
                  <h5 className="indus-card-headng">Dedicated Team</h5>
                  <p>
                    Unique Mind Solutions offers dedicated teams, providing expertise and support to drive your projects to success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='our-industry base-pad mt-1'>
        <div class="container-fluid">
          <div className="container-cust">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h6 className='sec-heading mt-5'>TECHNOLOGY INDEX</h6>
                <h2 className='base-h1 text-white'>
                  We Deliver Solution with the Goal of Trusting Relationships
                </h2>
              </div>
            </div>

            {/* slider start */}
            <br />
            <Splide
              options={{
                rewind: true,
                gap: '20px',
                autoplay: true,
                type: 'loop',
                drag: true,
                interval: 4000,
                perPage: 4,
                breakpoints: {
                  640: {
                    perPage: 1,
                  },
                  992: {
                    perPage: 2,
                  },
                  1200: {
                    perPage: 4,
                  },
                }
              }}
              aria-label="My Favorite Images"
            >

              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                  <i class="las la-laptop-code"></i>
                    <h5 className="indus-card-headng">Web Design</h5>
                    <p>
                      Unique Mind Solutions crafts captivating websites, blending creativity and functionality to elevate your online presence.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                  <i class="las la-shield-alt"></i>
                    <h5 className="indus-card-headng">Cyber Security</h5>
                    <p>
                      Protecting digital assets through cutting-edge technologies and proactive measures, ensuring robust cybersecurity for businesses.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                    <i class="las la-handshake"></i>
                    <h5 className="indus-card-headng">Business Services</h5>
                    <p>
                      Unique Mind Solutions offers tailored business services, empowering organizations with innovative solutions for sustainable growth.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                  <i class="las la-chart-bar"></i>
                    <h5 className="indus-card-headng">Digital Marketing</h5>
                    <p>
                      UMS revolutionizes digital marketing, crafting tailored strategies to amplify brand presence, engagement, and conversion rates..
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className='story-slider'>
                  <div className="industry-card">
                  <i class="las la-industry"></i>
                    <h5 className="indus-card-headng">Industry Service</h5>
                    <p>
                      UMS specializes in industry services, providing tailored solutions to address unique challenges and drive efficiency.
                    </p>
                  </div>
                </div>
              </SplideSlide>


            </Splide>
            {/* slider end */}
          </div>
        </div>
        <br />
        <br />
      </section>
    </div>
  )
}
