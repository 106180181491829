import React from 'react';
import env from '../../Config';
import { Link } from 'react-router-dom';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';


export default function index() {
  return (
    <>
      <div className='position-relative'>
        <img src={env.baseUrl + '/images/our-team-in-br.jpg'} className='img-fluid' />
        <div className="bredcremb">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h4 className='page-name'>Our Team</h4>
                <Link to='/'>Home</Link>
                <span className='mx-2'>/</span>
                <span>About Us</span>
                <span className='mx-2'>/</span>
                <span>Our Team</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container-cust ">
          <div className="row my-5">
            <div className="col-lg-6">
            <h6 className='sec-heading mt-5'>Our Team</h6>
              <h2 className="base-h1"> Our Connecting Leaders. Inspring Ideas</h2>
              <p>
              Our Team at Unique Mind Solutions embodies a diverse blend of talents, united by a passion for innovation and excellence. From seasoned veterans to fresh thinkers, each member contributes unique insights and skills, enriching our collaborative environment. We foster a culture of continuous learning and growth, empowering our team to stay ahead of the curve in an ever-evolving industry. With a shared dedication to exceeding expectations, we approach every project with enthusiasm and creativity, delivering solutions that inspire and drive success. Meet the faces behind our success and discover the difference our team can make for your business
              </p>
            </div>
            <div className="col-lg-6">
            <img src={env.baseUrl + '/images/our-team-img.avif'} className='img-fluid' />
            </div>
          </div>
        </div>
      </div>

<div className="container-fluid">
<div className="container-cust ">
<h4>Our Leadership</h4>

  SANJANA MARRI, SUMMAIYA MAHVEEN and SHEKAR.V are the visionary founders behind Unique Mind Solutions. Their passion for technology and commitment to delivering exceptional solutions have been instrumental in shaping the company's direction and culture. With their leadership, we continue to push boundaries and exceed expectations in the ever-evolving digital landscape.
</div>
</div>
     
      {/* <div className="textalign mt-5">
        <h6 className='sec-heading mt-5'>WE MAKE CONNECTIONS</h6>
        <h2 className="base-h1"> Discover Trust Team <p>and Our Experts</p></h2>
      </div> */}

      <div className="container-fluid mb-5">
        {/* <div className="container-cust">
          <div className="row">
            
            <div className="col-lg-8 offset-lg-2 mb-5 mt-3">
                  <Splide
                    options={{
                      rewind: true,
                      gap: '20px',
                      autoplay: true,
                      type: 'loop',
                      drag: true,
                      interval: 5000,
                      perPage: 3,
                      breakpoints: {
                        640: {
                          perPage: 1,
                        },
                        992: {
                          perPage: 3,
                        },
                        1200: {
                          perPage: 3,
                        },
                      }
                    }}
                    aria-label="My Favorite Images"
                  >
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                      <img src={env.baseUrl + '/images/team-mem-pic-1.jpg'} className='img-fluid' />
                        <h5>Sanjana Marry</h5>
                        <p>CEO</p>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                        <img src={env.baseUrl + '/images/team-mem-pic.jpg'} className='img-fluid' />
                        <h5>Shekhar V</h5>
                        <p>Tech Lead</p>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                        <img src={env.baseUrl + '/images/team-mem-pic.jpg'} className='img-fluid' />
                        <h5>Mumtaz M</h5>
                        <p>Manager</p>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                      <img src={env.baseUrl + '/images/team-mem-pic.jpg'} className='img-fluid' />
                      <h5>Upendar M</h5>
                        <p>Technical Lead</p>
                      </div>
                    </SplideSlide>
                    
                    <SplideSlide>
                      <div className='our-team-pics p-lg-2'>
                      <img src={env.baseUrl + '/images/team-mem-pic.jpg'} className='img-fluid' />
                      <h5>Harsha S</h5>
                        <p>Junior Software Engineer</p>
                      </div>
                    </SplideSlide>
                    </Splide>
                </div>
          </div>
        </div> */}


        {/* <div className="textalign" >
        <h6 className='sec-heading mt-5'>CLIENT FEEDBACK</h6>
        <h2>What Our Clients Say</h2>
        <p>Working with Unique Mind Solutions has been an absolute pleasure. Their professionalism, expertise, and </p>
          <p> dedication to delivering exceptional results are unparalleled. We're thrilled with the outcome and look forward to continued collaboration!</p>
      </div> */}


      </div>
    </>
  )
}
