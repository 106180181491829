import React from 'react'

export default function index() {
    return (
        <div>
            <div className="container-fluid">
                <div className="container-cust ">
                    <div className="my-5">
                        <h4>Privacy Policy</h4>
                        <p>At Unique Mind Solutions, we are committed to safeguarding the privacy of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website or services.</p>
                        <h4>Information We Collect</h4>

                        <p>We may collect personal information such as name, email address, phone number, and company details when you voluntarily provide it to us.
                            Additionally, we may automatically collect non-personal identification information, such as browser type, operating system, and IP address, through cookies and similar technologies.</p>

                        <h4>How We Use Your Information</h4>

                        We use the information we collect to provide and improve our services, communicate with you, personalize your experience, and ensure the security of our website and users' data. We may also use your information for marketing purposes, with your consent.

                        <h4>Data Security</h4>

                        <p>We prioritize the security of your personal information and take appropriate measures to protect it from unauthorized access, disclosure, alteration, or destruction.
                        </p>
                        <h4>Sharing Your Information</h4>

                        <p>We do not sell, trade, or rent users' personal information to third parties. However, we may share your information with trusted service providers who assist us in operating our website and providing our services, subject to confidentiality obligations.
                        </p>
                        <h4>Your Rights</h4>

                        <p>You have the right to access, correct, or delete your personal information. You may also opt-out of receiving marketing communications from us at any time.
                        </p>
                        <h4>Changes to This Policy</h4>

                        <p>We reserve the right to update this Privacy Policy at any time. We encourage you to review this page periodically for any changes.

                            By using our website or services, you consent to the terms of this Privacy Policy.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
