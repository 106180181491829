import React from 'react'
import env from '../../../Config'
import  './mobileStyles.scss'

const MobileDevelopment = () => {
  return (
    <div className='container-fluid base-pad pt-0'>
      <div className=''>
            <img src={env.baseUrl + '/images/mobile-app.jpg'} className='img-fluid'/>
      </div>
      <div>
          <div className='navibluebox-row'>
                  <div className='bigcol'>
                    <h2 className='base-h1'> Mobile App Development</h2>
                    <p>In today's digital age, mobile apps have become essential tools for businesses to engage with their customers, streamline operations, and drive growth. At UMS(Unique Mind Solution), we specialize in creating innovative mobile applications that deliver seamless experiences across iOS and Android platforms. Our team of experienced developers leverages cutting-edge technologies and industry best practices to turn your app ideas into reality and achieve your business objectives.</p>
                    <h3>Our Mobile App Development Services Offer</h3>
                    <div className='d-lg-flex d-md-flex justify-content-between'>
                    <ul>
                      <li className='py-2'>Custom App Development</li>
                      <li className='py-2'>Cross-Platform Development</li>
                      <li className='py-2'>Native App Development</li>
                    </ul>
                    <ul>
                    <li className='py-2'>UI/UX Design</li>
                      <li className='py-2'>Testing and Quality Assurance</li>
                    </ul>
                    </div>
                    
                  </div>
                  <div className='smallcol minus'>
                    <div className='icon-row'>
                      <div className='box'>
                          <div className='custom-web-icon'>
                          <img src={env.baseUrl + '/images/custom-web.png'} className='img-fluid'/>
                          </div>
                          <div>
                            <h4>Custom App Development</h4>
                          </div>
                      </div>
                    </div>
                    <div className='icon-row'>
                      <div className='box'>
                          <div className='custom-web-icon'>
                          <img src={env.baseUrl + '/images/e-commerce.png'} className='img-fluid'/>
                          </div>
                          <div>
                            <h4>Cross-Platform Development</h4>
                          </div>
                      </div>
                    </div>
                    <div className='icon-row'>
                      <div className='box'>
                          <div className='custom-web-icon'>
                          <img src={env.baseUrl + '/images/responsive.png'} className='img-fluid'/>
                          </div>
                          <div>
                            <h4>Native App Development</h4>
                          </div>
                      </div>
                    </div>
                    <div className='icon-row'>
                      <div className='box'>
                          <div className='custom-web-icon'>
                          <img src={env.baseUrl + '/images/cms.png'} className='img-fluid'/>
                          </div>
                          <div>
                            <h4>UI/UX Design</h4>
                          </div>
                      </div>
                    </div>
                    <div className='icon-row'>
                      <div className='box'>
                          <div className='custom-web-icon'>
                          <img src={env.baseUrl + '/images/support.png'} className='img-fluid'/>
                          </div>
                          <div>
                            <h4>Testing and Quality Assurance</h4>
                          </div>
                      </div>
                    </div>
                  </div>
          </div>
      </div>
      <div className='bg-white web py-3'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6 order-lg-1'>
                        <img src={env.baseUrl + '/images/custom-mobile-app.jpg'} className='img-fluid img-cust'/>
                  </div>
                  <div className='col-lg-6 order-lg-2'>
                    <div className='r-box'>
                        <h3> Custom App Development</h3>
                        <div className='d-flex justify-content-center align-items-center'>
                        <p>We specialize in creating custom mobile applications tailored to your specific business needs and objectives. Whether you're building a consumer-facing app, an enterprise solution, or a niche industry app, we have the expertise and experience to bring your ideas to life.</p>
                        </div>
                        <div>
                          <h4>Our Approach to Custom App Development</h4>
                          <ul>
                          <li>Discovery and Requirements Analysis</li>
                          <li>UI/UX Design</li>
                          <li>Development and Testing</li>
                          <li>Deployment and Support</li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className='bg-gray py-3'>
              <div className='container'>
                  <div className='row'>
                    <div className='col-lg-6 order-lg-1 d-none d-lg-block'>
                      <div className='l-box'>
                          <h3> Cross-Platform Development</h3>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p>Cross-platform development is a versatile approach to mobile app development that enables businesses to create applications that run seamlessly across multiple platforms, such as iOS and Android, using a single codebase. At UMS(Unique Mind Solutions), we specialize in cross-platform development solutions that offer cost-effectiveness, efficiency, and broad reach without compromising on performance or user experience. Our team of experienced developers leverages leading cross-platform frameworks to build high-quality apps that meet your specific business requirements and objectives.</p>
                            </div>
                            <div>
                              <h4>Our Cross-Platform Development Services Offer</h4>
                              <ul>
                              <li>React Native Development</li>
                              <li>Flutter Development</li>
                              <li>Ionic Development</li>
                              <li>Cross-Platform Testing and Optimization</li>
                              </ul>
                            </div>
                      </div>
                    </div>
                    <div className='col-lg-6 order-lg-2 d-none d-lg-block'>
                          <img src={env.baseUrl + '/images/cross-platform.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-6 d-block d-lg-none'>
                          <img src={env.baseUrl + '/images/cross-platform.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-6 d-block d-lg-none'>
                      <div className='l-box'>
                      <h3> Cross-Platform Development</h3>
                      <div className='d-flex justify-content-center align-items-center'>
                      <p>Cross-platform development is a versatile approach to mobile app development that enables businesses to create applications that run seamlessly across multiple platforms, such as iOS and Android, using a single codebase. At UMS(Unique Mind Solutions), we specialize in cross-platform development solutions that offer cost-effectiveness, efficiency, and broad reach without compromising on performance or user experience. Our team of experienced developers leverages leading cross-platform frameworks to build high-quality apps that meet your specific business requirements and objectives.</p>
                      </div>
                      <div>
                        <h4>Our Cross-Platform Development Services Offe</h4>
                        <ul>
                              <li>React Native Development</li>
                              <li>Flutter Development</li>
                              <li>Ionic Development</li>
                              <li>Cross-Platform Testing and Optimization</li>
                        </ul>
                      </div>
                      </div>
                      
                    </div>
                  </div>
              </div>
          </div>
          <div className='bg-white web py-3'>
              <div className='container'>
              <div className='row'>
                  <div className='col-lg-6 order-lg-1 d-lg-block'>
                          <img src={env.baseUrl + '/images/native.jpg'} className='img-fluid img-cust'/>
                  </div>
                  <div className='col-lg-6 order-lg-2 d-lg-block'>
                      <div className='r-box'>
                          <h3>Native App Development</h3>
                          <div className='d-flex justify-content-center align-items-center'>
                          <p>Native app development involves creating mobile applications specifically for a particular platform, such as iOS or Android, using platform-specific languages and development environments. At UMS(Unique Mind Solutions), we specialize in native app development solutions that leverage the unique capabilities and features of each platform to deliver optimal performance, user experience, and functionality. Our team of experienced developers has the expertise and technical proficiency to build high-quality native apps that meet your business objectives and exceed user expectations.</p>
                          </div>
                          <div>
                            <h4>Our Approach to Native App Development</h4>
                            <ul>
                            <li>Platform-Specific Development</li>
                            <li>User-Centric Design</li>
                            <li>Agile Development Methodology</li>
                            <li>Thorough Testing and Quality Assurance</li>
                            <li>Deployment and Maintenance</li>
                            </ul>
                          </div>
                      </div>
                  </div>
              </div>
              </div>
          </div>
          <div className='bg-gray py-3'>
              <div className='container'>
              <div className='row'>
                  <div className='col-lg-6 order-lg-1 d-none d-lg-block'>
                    <div className='l-box'>
                        <h3>UI/UX Design</h3>
                        <div className='d-flex justify-content-center align-items-center'>
                    <p>UI/UX design plays a pivotal role in the success of digital products by focusing on creating intuitive, user-friendly interfaces and delightful user experiences. At UMS(Unique Mind Solutions), we specialize in UI/UX design solutions that combine creativity, empathy, and technical expertise to craft visually stunning and highly functional interfaces that resonate with users and drive engagement. Our team of talented designers is dedicated to delivering exceptional UI/UX design services tailored to your specific business goals and target audience.</p>
                    </div>
                    <div>
                      <h4>Our Approach to UI/UX Design</h4>
                      <ul>
                      <li>User Research and Analysis</li>
                      <li>Wireframing and Prototypin</li>
                      <li>Visual Design and Branding</li>
                      <li>Usability Testing and Iteration</li>
                      <li>Responsive Design</li>
                      </ul>
                    </div>
                    </div>
                    
                  </div>
                  <div className='col-lg-6 order-lg-2 d-none d-lg-block'>
                        <img src={env.baseUrl + '/images/ui-ux.jpg'} className='img-fluid'/>
                  </div>
                  <div className='col-lg-6 d-block d-lg-none'>
                        <img src={env.baseUrl + '/images/ui-ux.jpg'} className='img-fluid'/>
                  </div>
                  <div className='col-lg-6 d-block d-lg-none'>
                  <div className='l-box'>
                        <h3>UI/UX Design</h3>
                        <div className='d-flex justify-content-center align-items-center'>
                    <p>UI/UX design plays a pivotal role in the success of digital products by focusing on creating intuitive, user-friendly interfaces and delightful user experiences. At UMS(Unique Mind Solutions), we specialize in UI/UX design solutions that combine creativity, empathy, and technical expertise to craft visually stunning and highly functional interfaces that resonate with users and drive engagement. Our team of talented designers is dedicated to delivering exceptional UI/UX design services tailored to your specific business goals and target audience.</p>
                    </div>
                    <div>
                      <h4>Our Approach to UI/UX Design</h4>
                      <ul>
                      <li>User Research and Analysis</li>
                      <li>Wireframing and Prototypin</li>
                      <li>Visual Design and Branding</li>
                      <li>Usability Testing and Iteration</li>
                      <li>Responsive Design</li>
                      </ul>
                    </div>
                    </div>
                    
                  </div>
                  
                </div>  
              </div>
              
          </div>
          <div className='bg-white web py-3'>
              <div className='container'>
              <div className='row'>
                  <div className='col-lg-6 order-lg-1 d-lg-block'>
                  <img src={env.baseUrl + '/images/quality.jpg'} className='img-fluid img-cust'/>
                  </div>
                  <div className='col-lg-6 order-lg-2 d-lg-block'>
                      <div className='r-box'>
                      <h3>Testing and Quality Assurance</h3>
                          <div className='d-flex justify-content-center align-items-center'>
                          <p>Testing and quality assurance (QA) are critical components of the software development lifecycle, ensuring that digital products meet the highest standards of performance, functionality, and reliability. At UMS(Unique Mind Solutions), we specialize in comprehensive testing and QA solutions that identify and address issues early in the development process, resulting in robust, bug-free software that delights users and exceeds expectations. Our dedicated team of QA professionals employs industry best practices, tools, and methodologies to deliver thorough testing and meticulous QA processes tailored to your specific project requirements.</p>
                          </div>
                          <div>
                          <h4>Our Testing and Quality Assurance Services Offer</h4>
                          <ul>
                          <li>Functional Testing</li>
                          <li>Performance Testing</li>
                          <li>Security Testing</li>
                          <li>Usability Testing</li>
                          <li>Regression Testing</li>
                          </ul>
                          </div>
                      </div>
                  </div>
              </div>
              </div>
          </div>
      
    </div>
  )
}

export default MobileDevelopment