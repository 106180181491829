
import './App.scss';

import Footer from './Components/Footer';
import Header from './Components/Header';
import Aboutus from './Screens/Aboutus';

import Home from './Screens/Home';
import WebDesign from './Screens/Services/WebDesigning';
import WebDevelopment from './Screens/Services/WebDevelopment';
import MobileDevelopment from './Screens/Services/MobileDevelopment';
import GraphicDesign from './Screens/Services/GraphicDesign';
import DigitalMarketing from './Screens/Services/DigitalMarketing';
import Contacts from './Screens/Contacts';
import WhyChooseUs from './Screens/WhyChooseUs';
import OurTeam from './Screens/OurTeam';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import Pagenotfound from './Screens/Pagenotfound';





import { BrowserRouter, Routes, Route } from 'react-router-dom';


const publicRoutes = [
  { path: '/', component: Home },
  { path: 'about-us', component: Aboutus },
  { path: 'web-design', component: WebDesign },
  { path: 'web-development', component: WebDevelopment },
  { path: 'mobile-development', component: MobileDevelopment },
  { path: 'grapic-design', component: GraphicDesign },
  { path: 'digital-marketing', component: DigitalMarketing },
  { path: 'contact-us', component: Contacts },
  { path: 'whychoose', component: WhyChooseUs },
  { path: 'ourteam', component: OurTeam },
  { path: 'privacy-policy', component: PrivacyPolicy },
  { path: '*', component: Pagenotfound },
]


function App() {




  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" index element={<Home />} />
          {publicRoutes.map(({ path, component: Component }) => (
            <Route key={'path-' + path} path={path} element={<Component />} />
          ))}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
