import React from 'react'
import './Styles.scss'
import { Link } from 'react-router-dom'


const Footer = () => {
  return (
    <>

      <section className="footer-main">
        <div className="container-fluid">
          <div className="container-cust">
            <div className="row">
              <div className="col-lg-3 col-md-6 pe-lg-5 border-r">
                <h5>Unique Mind Solutions</h5>
                <hr />
                <p>
                Where innovation meets expertise to transform your digital aspirations into reality. With creativity at our core, we empower businesses to thrive in the ever-evolving digital landscape
                </p>
              </div>

              {/* <div className="col-lg-6 col-md-6 px-lg-5"> 
              <h5 className='text-center'>Quick Links</h5>
              <hr />
              <div className="quick_links">
                <div>
                <ul>
                  <li><Link to='#'>Home</Link></li>
                  <li><Link to='#'>About Us</Link></li>
                  <li><Link to='#'>Services</Link></li>
                  <li><Link to="#">Careers</Link></li>
                  <li><Link to="#">Our Team </Link></li>
                  <li><Link to="#">Clients </Link></li>
                  <li><Link to="#">Contact Us </Link></li>
                  <li><Link to="#">Privacy </Link></li>
                </ul>
                </div>
               
                <div>
                <ul>
                  <li><Link to='#'>Ecommerce Development </Link></li>
                  <li><Link to='#'>PHP Development </Link></li>
                  <li><Link to='#'>Magento Development </Link></li>
                  <li><Link to='#'>Marketing Automation </Link></li>
                   </ul>
                </div>
              </div>
               </div> */}

              <div className="col-lg-3 col-md-6">
                <h5 className=''>Company</h5>
                <ul className='footer-links'>
                  <li><Link to='/'>Home</Link></li>
                  <li><Link to='/about-us'>About Us</Link></li>
                  <li><Link to='/web-design'>Services</Link></li>
                  {/* <li><Link to="#">Careers</Link></li> */}
                  <li><Link to="/ourteam">Our Team </Link></li>
                  {/* <li><Link to="#">Clients </Link></li> */}
                  <li><Link to="/contact-us">Contact Us </Link></li>
                  <li><Link to="/privacy-policy">Privacy Policy </Link></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6">
                <h5 className=''>Smart Web</h5>
                <ul className='footer-links'>
                  <li><Link to='#'>Ecommerce Development </Link></li>
                  <li><Link to='#'>PHP Development </Link></li>
                  <li><Link to='#'>WordPress Development </Link></li>
                  <li><Link to='#'>Shopify Development </Link></li>
                  <li><Link to='#'>Magento Development </Link></li>
                  <li><Link to='#'>Umbraco Development </Link></li>
                  <li><Link to='#'>Marketing Automation </Link></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6">
                <h5 className=''>Software Development</h5>
                <ul className=' footer-links'>
                  <li><Link to='#'>IOT App Development </Link></li>
                  <li><Link to='#'>Angular Development </Link></li>
                  <li><Link to='#'>ReactJS Development </Link></li>
                  <li><Link to='#'>Flutter App Development </Link></li>
                  <li><Link to="#">React Native App Development </Link></li>
                  <li><Link to="#">Android App Development </Link></li>
                  <li><Link to="#">iOS App Development  </Link></li>
                  <li><Link to="#">Full stack Development </Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='down-footer'>
        <div className="container text-center">
          <span> © 2024 Unique Mind Solutions Pvt Ltd. All Rights Reserved</span>
        </div>
      </section>
    </>
  )
}

export default Footer