import React from 'react'
import env from '../../../Config'
import './digitalstyles.scss'
const DigitalMarketing = () => {
  return (
    <div className='container-fluid base-pad pt-0'>
        <div>
            <div>
               <img src={env.baseUrl + '/images/digital-marketing-banner.jpg'} className='img-fluid'/>
            </div>
        </div>
        <div className='bg-white web p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3>Digital Marketing</h3>
                        <p className='py-3'>Our digital marketing solutions are designed to help businesses like yours thrive in today's competitive landscape. Whether you're looking to increase brand awareness, drive traffic to your website, or generate leads, we've got you covered.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-gray p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5 order-lg-2 d-none d-lg-block'>
                          <img src={env.baseUrl + '/images/strategy.jpg'} className='img-fluid' alt='Logo Design'/>
                    </div>
                    <div className='col-lg-7 order-lg-1 d-none d-lg-block'>
                      <h3>Strategic Social Media Management</h3>
                        <p>Harness the power of social media to connect with your audience, build relationships, and drive engagement. Our experts will create customized social media strategies tailored to your business objectives.</p>
                    </div>
                    <div className='col-lg-5 order-lg-1 d-block d-lg-none'>
                        <img src={env.baseUrl + '/images/strategy.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7 order-lg-2 py-4 d-block d-lg-none'>
                    <div className='r-box'>
                    <h3>Strategic Social Media Management</h3>
                    <p>Harness the power of social media to connect with your audience, build relationships, and drive engagement. Our experts will create customized social media strategies tailored to your business objectives.</p>
                    </div>
                   </div>
                    
                    
                </div>
            </div>
        </div>
        <div className='bg-white web p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <img src={env.baseUrl + '/images/seo.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7 d-none d-lg-block'>
                      <h3>Search Engine Optimization (SEO)</h3>
                        <p>Improve your website's visibility in search engine results and attract more organic traffic. Our SEO services include keyword research, on-page optimization, link building, and more.</p>
                    </div>
                    <div className='col-lg-7 py-4 d-block d-lg-none'>
                    <div className='r-box'>
                    <h3>Search Engine Optimization (SEO)</h3>
                    <p>Improve your website's visibility in search engine results and attract more organic traffic. Our SEO services include keyword research, on-page optimization, link building, and more.</p>
                    </div>
                   </div>
                </div>
            </div>
        </div>
        <div className='bg-gray p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7 order-lg-1 d-none d-lg-block'>
                      <h3>Content Marketing</h3>
                      <p>Deliver valuable, relevant content to your target audience and establish your brand as a thought leader in your industry. From blog posts and articles to videos and infographics, we'll help you create compelling content that resonates with your audience.</p>
                    </div>
                    <div className='col-lg-5 order-lg-2 d-none d-lg-block'>
                        <img src={env.baseUrl + '/images/content-marketing.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-5 order-lg-1 d-block d-lg-none'>
                        <img src={env.baseUrl + '/images/content-marketing.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7 order-lg-2 d-block d-lg-none '>
                    <div className='r-box'>
                    <h3>Content Marketing</h3>
                      <p>Deliver valuable, relevant content to your target audience and establish your brand as a thought leader in your industry. From blog posts and articles to videos and infographics, we'll help you create compelling content that resonates with your audience.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-white web p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <img src={env.baseUrl + '/images/email.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7'>
                      <h3>Email Marketing Campaigns</h3>
                        <p>Stay top-of-mind with your customers and nurture leads through personalized email marketing campaigns. From welcome emails to promotional offers, we'll help you craft engaging email content that drives results.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-gray p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7 order-lg-1 d-none d-lg-block'>
                      <h3>Pay-Per-Click (PPC) Advertising</h3>
                        <p>Reach your target audience with precision-targeted PPC advertising campaigns. Whether you're looking to increase website traffic, generate leads, or drive sales, we'll help you maximize your ROI with strategic ad placements.</p>
                    </div>
                    <div className='col-lg-5 order-lg-2 d-none d-lg-block'>
                        <img src={env.baseUrl + '/images/payperclick.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-5 d-block d-lg-none'>
                        <img src={env.baseUrl + '/images/payperclick.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7 d-block d-lg-none'>
                    <div className='r-box'>
                    <h3>Pay-Per-Click (PPC) Advertising</h3>
                        <p>Reach your target audience with precision-targeted PPC advertising campaigns. Whether you're looking to increase website traffic, generate leads, or drive sales, we'll help you maximize your ROI with strategic ad placements.</p>
                    </div>
                      
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default DigitalMarketing