import React from 'react';
import env from '../../Config';

export default function index() {
  return (
    <div className='text-center'>
        <h2 className='mt-5'>Page Not Found</h2>
         <img src={env.baseUrl + '/images/page-not-found.jpg'} className='img-fluid p-lg-5' />
    </div>
  )
}
