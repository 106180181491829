import React from 'react'
import { Link } from 'react-router-dom';
import './Styles.scss';
import env from '../../Config';



const Header = () => {
    return (
        <>
            {/* new header start */}
            <section className="top-nav">
                <div className="container-fluid">
                    <div className="container-cust">
                        <div className="row w-100">
                            <div className="col-lg-5">
                                <i className="las la-phone-alt"></i> +91 770 2223 007
                                <span className="text-secondary mx-3"> | </span> <a
                                    href="#" className="text-decoration-none"><i className="las la-envelope"></i>
                                    info@UniqueMindSolutions.com</a>
                            </div>
                            <div className="col-lg-7 d-flex justify-content-end ">
                                <div className="d-none d-md-block">

                                    <span className="">Office Hour : 09:00am - 6:00pm</span>
                                    <span className="text-secondary mx-3"> | </span>
                                    <span className="top-social-icons justify-content-end d-inline-flex">
                                        <a href="#" className="icon-social-media"><i className="lab la-facebook-f"></i></a>
                                        <a href="#" className="icon-social-media"><i className="lab la-twitter"></i></a>
                                        <a href="#" className="icon-social-media"><i className="lab la-instagram"></i></a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="container-fluid main-nav sticky-top bg-white shadow-sm bg-light">
                <div className="container-cust">
                    <nav className="navbar navbar-expand-lg navbar-light p-lg-0">
                        <a className="navbar-brand" href="/">
                            {/* <img src={env.baseUrl + '/images/logo.png'} alt="" className="logo-main img-fluid" /> */}
                            <img src={env.baseUrl + '/images/logo.png'} className="logo-main img-fluid" alt='Our Partners' />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto ms-lg-5 ps-lg-5 gap-4">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link">Home</Link>
                                </li>
                                <li className="nav-item dropdown">

                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">About Us</a>
                                    <ul className="dropdown-menu">
                                        <Link className="dropdown-item" to="/about-us">Company Profile</Link>
                                        <Link className="dropdown-item" to="/whychoose">Why Choose Us</Link>
                                        <Link className="dropdown-item" to="/ourteam">Our Team</Link>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Services</a>
                                    <ul className="dropdown-menu">
                                        <Link className="dropdown-item" to="/web-design">Web Designing</Link>
                                        <Link className="dropdown-item" to="/web-development">Web Development</Link>
                                        <Link className="dropdown-item" to="/mobile-development">Mobile Development</Link>
                                        <Link className="dropdown-item" to="/grapic-design">Graphic Design</Link>
                                        <Link className="dropdown-item" to="/digital-marketing">Digital Marketing</Link>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <span className="border-start m-lg-3 d-none d-lg-block">
                            <Link to='/contact-us' className="btn-msg btn btn-outline-warning orange-bg text-white fs-5 ms-lg-5"> Leave a Message
                            </Link>
                        </span>

                    </nav>
                </div>
            </div>
            {/* new header end */}

            {/* <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">Logo</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about-us">About Us</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Service</a>
                                <ul className="dropdown-menu">
                                    <Link className="dropdown-item" to="/web-design">Web Designing</Link>
                                    <Link className="dropdown-item" to="/web-development">Web Development</Link>
                                    <Link className="dropdown-item" to="/mobile-development">Mobile Development</Link>
                                    <Link className="dropdown-item" to="/grapic-design">Graphic Design</Link>
                                    <Link className="dropdown-item" to="/digital-marketing">Digital Marketing</Link>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact-us">Contact Us</Link>
                            </li>




                        </ul>
                    </div>
                </div>
            </nav> */}
        </>
    )
}

export default Header