import React from 'react'
import env from '../../../Config'

import  './design.scss'
const WebDesign = () => {
  return (
    <div className='container-fluid base-pad pt-0'>
        <div>
            <div>
               <img src={env.baseUrl + '/images/web-design-banner.jpg'} className='img-fluid'/>
            </div>
        </div>
        <div className='bg-white p-3'>
            <div className='container-fluid'>
            <div className='container-cust'>
                <div className='row'>
                    <div className='col-lg-6 mt-5 pt-lg-5 pe-lg-4'>
                        <h3>Web Design Service</h3>
                        <p className='py-3'>Web design is the process of creating visually appealing and user-friendly websites that engage visitors, communicate brand identity, and achieve business objectives. At UMS(Unique Mind Solutions), we specialize in innovative web design solutions that combine creativity, functionality, and usability to deliver exceptional online experiences for businesses of all sizes and industries. Our team of experienced designers leverages the latest design trends, technologies, and best practices to create custom websites that captivate audiences, drive conversions, and leave a lasting impression.</p>
                    </div>
                    <div className='col-lg-6 mt-5'>
                     <img src={env.baseUrl + '/images/web-design.avif'} className='img-fluid'/>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className='bg-gray p-3'>
            <div className='container'>
            <div className='col-lg-12'>
                    <div className='py-3'>
                      <h3>Our Approach to Web Design</h3>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='single-box'>
                            <h4>Discovery and Research</h4>
                            <p>We start by gaining a deep understanding of your business goals, target audience, and competitive landscape. Through research and analysis, we identify key insights and opportunities that inform the design strategy and guide decision-making throughout the process.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                        <div className='single-box'>
                        <h4>Wireframing and Prototyping</h4>
                            <p>We create wireframes and prototypes to visualize the layout, structure, and flow of the website before diving into full design. This iterative process allows us to explore different design concepts, gather feedback from stakeholders, and refine the user experience before moving into visual design.</p>
                        </div>
                        </div>
                        <div className='col-lg-4'>
                        <div className='single-box'>
                        <h4>Visual Design and Branding</h4>
                            <p>We design visually appealing and cohesive interfaces that align with your brand identity and resonate with your target audience. From typography and color palettes to imagery and graphic elements, we create designs that evoke emotion, communicate messaging, and guide users through the website.</p>
                        </div>
                       </div>
                    </div>
                    <div className='row py-3'>
                        <div className='col-lg-4'>
                        <div className='single-box'>
                        <h4>Content Creation and Optimization</h4>
                            <p>We collaborate with you to develop compelling and engaging content that communicates your brand story, products, and services effectively. Through copywriting, photography, and multimedia production, we create content that captures attention, builds trust, and drives action.</p>
                        </div>
                        </div>
                        <div className='col-lg-4'>
                        <div className='single-box'>
                        <h4>Development and Testing</h4>
                            <p>We bring the designs to life through front-end and back-end development, using the latest technologies and coding standards to ensure optimal performance, security, and scalability. Throughout the development process, we conduct thorough testing and quality assurance to identify and address any issues or bugs, ensuring a seamless and error-free user experience.</p>
                        </div>
                         </div>
                        <div className='col-lg-4'>
                        <div className='single-box'>
                        <h4>Launch and Optimization</h4>
                            <p>Once the website is ready, we handle the deployment and launch, ensuring a smooth transition from development to production. We continue to monitor website performance, gather user feedback, and analyze data to identify opportunities for optimization and improvement, ensuring that your website evolves to meet the changing needs and expectations of your audience.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default WebDesign